<template>
  <div class="main">
    <!-- 顶部 -->
    <div class="main-top">
      <img class="top-bar" src="@/assets/images/mobile/turntable/bg1.png" />
      <div class="main-top-title">
        <img class="img-lefts" src="@/assets/images/mobile/turntable/bg2.png" />
        <div class="title">100%中奖 好运转不停</div>
        <img class="img-right" src="@/assets/images/mobile/turntable/bg2.png" />
      </div>
      <div class="lottery-title" v-if="prizeNumber || prizeNumber === 0">
        已有{{ prizeNumber }}人参与抽奖
      </div>
    </div>

    <div
      class="content"
      :style="{
        backgroundImage: 'url(' + imgSrc + ')'
      }"
    >
      <div class="lottery">
        <div
          class="lottery-wheel"
          :style="{
            '-webkit-transition': wheelTransition,
            '-webkit-transform': rotateAngle
          }"
        >
          <div
            class="prize-item"
            v-for="(item, index) in prizeList"
            :key="index"
          >
            <img :src="`${http}WeChat/Images/lottery/${item.ThumbImageUrl}`" />
          </div>
        </div>
        <div class="lotterydraw" @click="lotterydraw">
          <div class="lotterydraw-top">立即</div>
          <div>抽奖</div>
        </div>
      </div>
    </div>
    <!-- 抽奖机会剩余 -->
    <div class="prize-opportunity">
      <div class="prize-box-fa">
        <div class="prize-box">你还有{{ prizeOpportunity }}次抽奖机会</div>
      </div>
      <img class="prize-img" src="@/assets/images/prize-opportunity.png" />
    </div>

    <!-- 中奖名单 -->
    <div class="winning-list">
      <div class="winning-list-top">
        <img class="img-lefts" src="@/assets/images/mobile/turntable/bg4.png" />
        <div>我的中奖记录</div>
        <img class="img-right" src="@/assets/images/mobile/turntable/bg4.png" />
      </div>
      <div class="list-box" @scroll="scroll">
        <div
          class="list-item"
          v-for="(item, index) in resultsList"
          :key="index"
        >
          <div class="list-item-left">{{ item.CreateDate | fromDate }}</div>
          <div class="list-item-name">
            {{
              item.AwardName === "谢谢参与"
                ? item.AwardName
                : "恭喜你获得" + item.AwardName
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="zhu-jiao">
      <img class="jiao-lefts" src="@/assets/images/mobile/turntable/jiao.png" />
      <img class="jiao-right" src="@/assets/images/mobile/turntable/jiao.png" />
    </div>
    <!-- 中奖名单 -->
    <div class="winning-list-fa">
      <div class="winning-list  winning-list-c">
        <div class="winning-list-top">
          <img
            class="img-lefts"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
          <div>中奖人员名单</div>
          <img
            class="img-right"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
        </div>
        <div class="list-box">
          <div
            class="list-item2"
            v-for="(item, index) in LatestResults"
            :key="index"
          >
            <div class="list-item-left">{{ item.Dateline | fromDate }}</div>
            <div class="list-item-content">
              {{ item.AwardName }}
            </div>
            <div class="list-item-name">
              {{ item.MemberKey }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zhu-jiao zhu-jiao2">
      <img class="jiao-lefts" src="@/assets/images/mobile/turntable/jiao.png" />
      <img class="jiao-right" src="@/assets/images/mobile/turntable/jiao.png" />
    </div>
    <!-- 礼品介绍 -->
    <div class="gift-box-fa">
      <div class="gift-box">
        <div class="gift-list-top">
          <img
            class="img-lefts"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
          <div>礼品介绍</div>
          <img
            class="img-right"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
        </div>
        <div class="card-box">
          <div
            class="card-item"
            v-for="(item, index) in prizeList"
            :key="index"
          >
            <img :src="`${http}WeChat/Images/lottery/${item.ThumbImageUrl}`" />
          </div>
        </div>
      </div>
    </div>
    <!-- 为你推荐 -->
    <div class="zhu-jiao zhu-jiao3">
      <img class="jiao-lefts" src="@/assets/images/mobile/turntable/jiao.png" />
      <img class="jiao-right" src="@/assets/images/mobile/turntable/jiao.png" />
    </div>
    <div class="recommend-box-fa">
      <div class="recommend-box">
        <div class="gift-list-top">
          <img
            class="img-lefts"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
          <div>为你推荐</div>
          <img
            class="img-right"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
        </div>
        <div class="red-box">
          <div class="red-box-item">
            <div class="book-left">
              <img
                class="book"
                src="@/assets/images/mobile/turntable/book.png"
              />
              <span>参与问卷获取更多抽奖卡</span>
            </div>
            <div
              class="btn"
              @click="goHref('https://www.sudiaoba.com/wechat/default.aspx')"
            >
              去答卷
            </div>
          </div>
        </div>
        <div class="red-box">
          <div class="red-box-item">
            <div class="book-left">
              <img
                class="book"
                src="@/assets/images/mobile/turntable/star.png"
              />
              <span>推荐好友得金币</span>
            </div>
            <div
              class="btn"
              @click="goHref('https://www.sudiaoba.com/wechat/Invitation.aspx')"
            >
              去推荐
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 活动规则 -->
    <div class="text-box">
      <div class="text1">活动规则</div>
      <div class="text2">
        2025年1月1日起，新版速调吧推出“速调吧幸运抽奖100%中奖”大转盘活动，让会员在回答问卷的同时能获得更多抽奖机会，100%获奖。
        <p>
          奖品设置：
          速调吧会不定期更新奖品品类，本期设置，1金币、10金币、50金币、100金币、500金币、5000金币
        </p>
        <p>如何获得抽奖机会：</p>
        速调吧的抽奖机会从两个途径获得：
        <br />①、答卷冲关随机获得抽奖机会 <br />
        ②、参与速调吧公众号或者官网推出的活动随机赠送抽奖机会。
        <br />③、给速调吧提出宝贵建议一旦采用获得更多抽奖机会。
        <p>注意：本活动只在新版速调吧中推出</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
export default {
  filters: {
    fromDate(data) {
      return data.slice(0, 16);
    }
  },
  data() {
    return {
      LuckyIndex: 0, // 回馈下标
      wheelStartRotateDegree: 0, // 转盘初始化角度
      wheelTransition: "transform  5s ease-in-out", // 初始化转盘
      rotateAngle: 0, // 转盘要转的角度
      rotating: false, //转盘完成在次开始判断
      prizeList: [], //转盘数据
      lotteryname: "", //中奖奖品名称
      lotteryshow: false,
      imgSrc: require("@/assets/images/mobile/turntable/bg3.png"),
      resultsList: [],
      LatestResults: [],
      http: process.env.VUE_APP_SERVER,

      lotteryID: null,
      Remark: null,

      prizeNumber: null, //抽奖人数
      prizeOpportunity: 0, //抽奖机会
      winningType: 1,
      flag: false,
      page: 1
    };
  },
  methods: {
    async lotterydraw() {
      if (!this.rotating) {
        await this.setLottery();
        // 转盘方法
        if (this.lotteryID) {
          this.gameRotate(this.lotteryID);
        }
      }
    },
    // 转盘逻辑
    gameRotate(lotteryID) {
      this.rotating = true;
      const len = this.prizeList.length;

      for (let i = 0; i < len; i++) {
        if (this.prizeList[i].ID === lotteryID) {
          this.LuckyIndex = i;
        }
      }
      console.log("index", this.LuckyIndex);

      // const result = [0, 36, 72, 108, 144, 180, 216, 252, 288, 324];
      const result = [0, 324, 288, 252, 216, 180, 144, 108, 72, 36];
      let FIXED_TIMES = 5;
      const addDegree = (FIXED_TIMES + 1) * 360;
      let rotateAngleNum =
        this.wheelStartRotateDegree +
        addDegree +
        result[this.LuckyIndex] -
        (this.wheelStartRotateDegree % 360);
      this.wheelStartRotateDegree = rotateAngleNum; // 转盘初始化角度
      this.rotateAngle = `rotate(${rotateAngleNum}deg)`; //转盘要转的角度
      console.log("1111", rotateAngleNum % 360);
      setTimeout(() => {
        this.gameover();
      }, FIXED_TIMES * 1000 + 600); // 延时，保证转盘转完
    },
    // 转盘转完可再次转
    gameover() {
      this.rotating = false;
      //   中奖奖品
      this.lotteryshow = true;
      this.lotteryID = null;
      Message.success(this.Remark);
      this.getLatestChances();
      this.getLatestResults2();
    },
    goHref(path) {
      location.href = path;
    },
    //获取奖项列表
    async getAwards() {
      const data = await this.$http.get("/api/v1/Awards");
      let response = JSON.parse(data.request.response);
      if (response.status === 200) {
        console.log("data", response);
        this.prizeList = response.response;
      }
    },
    //我的中奖记录
    async getResults() {
      this.flag = false;
      const data = await this.$http.get(
        `/api/v1/Lottery/Results?page=${this.page}&size=10`
      );
      let response = JSON.parse(data.request.response);
      if (response.status === 200) {
        console.log("我的中奖记录", response.response.data);
        this.resultsList = this.resultsList.concat(response.response.data);
        if (response.response.data.length !== 0) {
          this.flag = true;
          this.page++;
        }
      }
    },
    scroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollHeight - (scrollTop + clientHeight) <= 0) {
        console.log("1111", scrollHeight - (scrollTop + clientHeight));
        if (this.flag) {
          this.getResults();
        }
      }
    },
    // 最近中奖记录
    async getLatestResults() {
      const data = await this.$http.get("/api/v1/Lottery/LatestResults");
      let response = JSON.parse(data.request.response);
      if (response.status === 200) {
        console.log("最近中奖记录", response.response);
        this.LatestResults = response.response;
      }
    },
    //最近抽奖人数
    async getLatestResults2() {
      const data = await this.$http.get("/api/v1/Lottery/LatestResults");
      let response = JSON.parse(data.request.response);
      if (response.status === 200) {
        this.prizeNumber = response.extend;
      }
    },

    // 抽奖机会
    async getLatestChances() {
      const data = await this.$http.get(
        "/api/v1/Lottery/Chance/Stats?used=false"
      );
      let response = JSON.parse(data.request.response);
      if (response.status === 200) {
        this.prizeOpportunity = response.response;
      }
    },

    //抽奖
    async setLottery() {
      const data = await this.$http.post("/api/v1/Lottery");
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        this.lotteryID = response.response.ID;
        this.Remark = response.response.Remark;
      } else {
        Message.warning(response.msg);
      }
    }
  },
  async created() {
    this.getAwards();
    this.getLatestResults();
    this.getLatestResults2();
    this.getLatestChances();
    this.getResults();
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.main {
  background-color: #ff3d3a;
  width: 100%;
  min-height: calc(100vh - 50px);
  // padding-bottom: 50px;

  .main-top {
    width: 100%;
    position: relative;

    .top-bar {
      width: 100%;
      position: relative;
      z-index: 1;
    }

    .main-top-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-10%);
      padding: 0 3.75vw;
      height: 4.875vw;
      line-height: 4.875vw;
      border-radius: 2.4375vw;
      text-align: center;
      color: #ff2c2c;
      background: radial-gradient(12% 51% at 50% 12%, #fffbf1 0%, #fdd8a3 100%);
      z-index: 2;
      font-size: 2.25vw;
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        flex-shrink: 0;
      }

      img {
        width: 3.1875vw;
        height: 0.1875vw;
        flex-shrink: 0;
      }

      .img-lefts {
        margin-right: 1.875vw;
        transform: rotate(180deg);
      }

      .img-right {
        margin-left: 1.875vw;
      }
    }

    .lottery-title {
      position: absolute;
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 2.25vw;
      top: 50%;
      transform: translateY(8vw);
    }
  }

  .content {
    width: 100vw;
    height: 129vw;
    background-size: 100% 100%;
    background-position: center;
    position: relative;

    .content-tips {
      position: absolute;
      bottom: 30px;
      text-align: center;
      width: 100%;
      color: #fff;
      font-size: 14px;
    }
  }
}

.lottery {
  position: relative;
  width: 74vw;
  height: 74vw;
  left: 50%;
  top: 20vw;
  transform: translateX(-50%);
}

.lottery-wheel {
  width: 74vw;
  height: 74vw;
  margin: auto;
  background: url("https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/lunpan.png")
    0 0 no-repeat;
  background-size: 100% 100%;

  .prize-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 10vw;
    height: 10vw;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 奖品样式
  .prize-item:nth-child(1) {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .prize-item:nth-child(2) {
    position: absolute;
    top: 25%;
    left: 68%;
    transform: translateX(-50%) translateY(-50%) rotate(36deg);
  }

  .prize-item:nth-child(3) {
    position: absolute;
    top: 40.5%;
    left: 79%;
    transform: translateX(-50%) translateY(-50%) rotate(72deg);
  }

  .prize-item:nth-child(4) {
    position: absolute;
    bottom: 40.5%;
    left: 79%;
    transform: translateX(-50%) translateY(50%) rotate(108deg);
  }

  .prize-item:nth-child(5) {
    position: absolute;
    bottom: 25%;
    left: 68%;
    transform: translateX(-50%) translateY(50%) rotate(144deg);
  }

  .prize-item:nth-child(6) {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .prize-item:nth-child(7) {
    position: absolute;
    bottom: 25%;
    right: 68%;
    transform: translateX(50%) translateY(50%) rotate(216deg);
  }

  .prize-item:nth-child(8) {
    position: absolute;
    bottom: 40.5%;
    right: 79%;
    transform: translateX(50%) translateY(50%) rotate(252deg);
  }

  .prize-item:nth-child(9) {
    position: absolute;
    top: 40.5%;
    right: 79%;
    transform: translateX(50%) translateY(-50%) rotate(288deg);
  }

  .prize-item:nth-child(10) {
    position: absolute;
    top: 25%;
    right: 68%;
    transform: translateX(50%) translateY(-50%) rotate(324deg);
  }
}

.lotterydraw {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 5;
  width: 15vw;
  height: 16.8vw;
  background: url("../../assets//images/xbzq/zqan.png") 0 0 no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.25vw;
  color: #fff;
  letter-spacing: 1px;
  cursor: pointer;

  .lotterydraw-top {
    margin-top: 1.875vw;
  }
}

.winning-list-fa {
  width: 88vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(360deg, #ffc48d 0%, #fa9638 100%);
  border-radius: 10px 10px 10px 10px;
  padding-top: 8vw;
  top: -6vw;
}

// .winning-list-c{
//   margin-top: 8vw;
// }
.winning-list {
  width: 88vw;
  height: 60vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, #ffe4c6 0%, #ffe4c6 100%);
  border-radius: 2vw;
  border: 4px solid #fff;

  .winning-list-top {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.5vw;
    color: #ff3434;
    font-weight: bolder;
    letter-spacing: 0.5vw;
    margin-top: 2vw;

    img {
      width: 10vw;
      height: 1vw;
    }

    .img-lefts {
      transform: rotate(180deg);
      margin-right: 2vw;
    }

    .img-right {
      margin-left: 2vw;
    }
  }

  .list-box {
    height: 45vw;
    overflow: auto;
    margin-top: 3vw;

    .list-item {
      display: flex;
      justify-content: center;
      color: #ff5840;
      font-size: 2.5vw;
      margin-bottom: 3vw;

      .list-item-left {
        width: 30vw;
        flex-shrink: 0;
        margin-right: 2vw;
        text-align: center;
      }

      .list-item-name {
        width: 30vw;
        text-align: center;
        // margin-right: 10vw;
      }
    }

    .list-item2 {
      display: flex;
      justify-content: center;
      color: #ff5840;
      font-size: 2.5vw;
      margin-bottom: 3vw;

      .list-item-left {
        flex-shrink: 0;
        width: 30vw;
        text-align: center;
      }

      .list-item-content {
        width: 20vw;
        text-align: center;
        margin: 0 1vw;
      }

      .list-item-name {
        text-align: center;
        width: 30vw;
        // margin-right: 10vw;
      }
    }
  }
}

.zhu-jiao2 {
  top: -6vw;
}

.zhu-jiao {
  width: 88vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  z-index: 9;

  img {
    width: 8vw;
    height: 11vw;
  }

  .jiao-lefts {
    margin-left: 14vw;
  }

  .jiao-right {
    margin-right: 14vw;
  }
}

.zhu-jiao3 {
  top: -12vw;
}

.gift-box-fa {
  width: 88vw;
  position: relative;
  top: -12vw;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  background: linear-gradient(360deg, #ffc48d 0%, #fa9638 100%);
  border-radius: 10px 10px 10px 10px;

  .gift-box {
    margin-top: 8vw;
    background: linear-gradient(180deg, #ffe4c6 0%, #ffe4c6 100%);
    border-radius: 8px;
    border: 4px solid #fff;
    width: 100%;

    .gift-list-top {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 4.5vw;
      color: #ff3434;
      font-weight: bolder;
      letter-spacing: 0.5vw;
      margin-top: 2vw;

      img {
        width: 10vw;
        height: 1vw;
      }

      .img-lefts {
        transform: rotate(180deg);
        margin-right: 2vw;
      }

      .img-right {
        margin-left: 2vw;
      }
    }

    .card-box {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0 3vw;
      margin-top: 6vw;

      .card-item {
        width: 25vw;
        height: 25vw;
        border-radius: 4px;
        // background-color: #fff;
        margin-bottom: 4vw;
        margin-right: calc((100% - 75vw) / 2);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        img {
          width: 20vw;
          // height: 100%;
        }
      }

      .card-item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

.recommend-box-fa {
  width: 88vw;
  position: relative;
  top: -18vw;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  background: linear-gradient(360deg, #ffc48d 0%, #fa9638 100%);
  border-radius: 10px 10px 10px 10px;

  .recommend-box {
    margin-top: 8vw;
    background: linear-gradient(180deg, #ffe4c6 0%, #ffe4c6 100%);
    border-radius: 8px;
    border: 4px solid #fff;
    width: 100%;

    .gift-list-top {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 4.5vw;
      color: #ff3434;
      font-weight: bolder;
      letter-spacing: 0.5vw;
      margin-top: 2vw;

      img {
        width: 10vw;
        height: 1vw;
      }

      .img-lefts {
        transform: rotate(180deg);
        margin-right: 2vw;
      }

      .img-right {
        margin-left: 2vw;
      }
    }

    .red-box {
      width: calc(100% - 24px);
      margin-left: 12px;

      .red-box-item {
        width: 100%;
        height: 74px;
        background: linear-gradient(176deg, #f95d4c 0%, #f33a36 100%);
        border-radius: 7px;
        margin-top: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .book-left {
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 14px;
          margin-left: 4vw;

          .book {
            width: 36px;
            height: 36px;
            margin-right: 2vw;
          }
        }

        .btn {
          width: 57px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          color: #f13b07;
          font-size: 13px;
          margin-right: 4vw;
          background: linear-gradient(
            180deg,
            #fdf0db 0%,
            #ffb672 50%,
            #fee2c3 98%
          );
          box-shadow: 0px 2px 2px 0px #e7332f;
          border-radius: 25px 25px 25px 25px;
        }
      }
    }
  }
}

.text-box {
  position: relative;
  top: -10vw;
}

.text1 {
  color: #fff5f2;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.text2 {
  width: calc(100% - 48px);
  margin-left: 24px;
  font-size: 14px;
  color: #fff;
  margin-top: 12px;

  p {
    margin-top: 10px;
  }
}

//抽奖机会
.prize-opportunity {
  position: relative;
  top: -7vw;

  .prize-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 55.9vw;
    height: 11.2vw;
  }

  .prize-box-fa {
    position: relative;
    top: 4vw;
    left: 50%;
    transform: translateX(-50%);
    width: 49.8vw;
    height: 8.4vw;
    background: #ff9c32;
    border-radius: 4.2vw;
    z-index: 1;
  }

  .prize-box {
    width: 49.8vw;
    height: 7.4vw;
    text-align: center;
    line-height: 7.4vw;
    color: #d32f17;
    font-size: 3.6vw;
    background: #ffc932;
    box-shadow: inset 0px 0.5vw 3vw 0px #ffffff;
    border-radius: 3.7vw;
    border-image: linear-gradient(
        200deg,
        rgba(255, 148.000006377697, 49.00000087916851, 1),
        rgba(255, 255, 255, 1)
      )
      1 1;
  }
}
</style>
